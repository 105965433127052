import { Component, Vue } from "vue-property-decorator";

import { Header,Footer } from "@/layout";
import { ClassModel } from "@/models";
import { TeCommon } from "../Common";

type response = { [key: string]: unknown };

@Component<ApTeacherClassStudentController>({
    components: {
        Header,Footer
    }
})
export default class ApTeacherClassStudentController extends Vue {
    /*定義data 變數 */
    private doubleClick = false;
    private studentList: object = {};
    private clid = this.$route.query.clid;
    private num = 0;
    private page = 1;
    private perpage = 50;
    private maxPage = 0;
    private token = window.localStorage.getItem('teacherToken') as string;

    public created() {
        this.getStudentList();
    }

    public async getStudentList() {
        const item = {
            page: this.page,
            perpage: this.perpage,
            clid: this.clid,
            token: this.token,
        }
        const data = await ClassModel.teGetStudentList(item) as response;
        TeCommon.checkToken(data.ERR_CODE as number);
        this.num = data.num as number;
        this.studentList = data.list as object;
        this.maxPage = Math.ceil(this.num/this.perpage);
    }

    private getPageList(page: number) {
        this.page = (page < 1) ? 1 : (page > this.maxPage) ?  this.maxPage : page;
        this.getStudentList();
    }
}